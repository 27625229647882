import { IFestival, IHoneycombs, IMilestone, IProject, IProjectMilestone } from "../data/interfaces"; 
import { IState, IUser, useMainStore } from "@/settings/zustand";
import { isStatusOpened } from "../data/utils/status";
import { useFestDataStore, useFestMyProjects } from "../data/store"; 
import { actions } from "../data/actions";
import { GET_OWN_ACTION } from "../data/actionTypes";
import { isRole } from "@/settings/zustand/user";
import { ID, Role } from "@/libs/interfaces/layouts";

let isStartSubscribe: boolean = false 

function useUserAction( project: IProject, festId: ID | null = null ){ 
    const user: IUser = useMainStore.getState().user 
    return !!user.id 
        &&
        ( user.id === project.tutor?.id || user.id === project.leader?.id )
        &&
        isStatusOpened()
}
 

function useUserOwner( project: IProject, festId: ID | null = null ) : boolean { 
    let isUserOwner: boolean = false
    if(!isStartSubscribe) {
        useMainStore.subscribe( (state: any) => {
            isStartSubscribe = true
            isUserOwner = !!state.user.id &&
            ( 
                state.user.id === project.tutor?.id || 
                state.user.id === project.leader?.id 
            )
        }) 
    }
    const user: IUser = useMainStore.getState().user 
    isUserOwner =  !!user.id 
        &&
        ( user.id === project.tutor?.id || user.id === project.leader?.id )
    return isUserOwner 
}
function isMyPartner ( project: IProject ) {
    const honeycombs:IHoneycombs = project.honeycombs
    if(!honeycombs?.id) {
        return false
    }        
    const myProjects: IProject[] = useFestMyProjects.getState().myProjects
    return !!myProjects.filter( (project:IProject) => project.honeycombs?.id === honeycombs?.id ).length
}

function useOwnerPartner(project: IProject, festId: ID | null = null) {  
    return isMyPartner(project)
}

function useMyProjects( festId: ID | null = null ): IProject[] {  
    const setMyProjects = useFestMyProjects.getState().setMyProjects
    if(!isStartSubscribe) {
        useMainStore.subscribe( (state: any) => { 
            isStartSubscribe = true
            if(!!state.user.id) {
                actions(GET_OWN_ACTION, { land_id: festId })
            }
            else {
                setMyProjects([])
            }
        }) 
    } 
    return useFestMyProjects.getState().myProjects
}

function useIsExpert( project:IProject, milestone: IMilestone, label: ID | null = null ): boolean {
    // 1. Если Этап самооценочный, а ты - Наставник или другой участник этого проекта
    // 2. Если ты - Эксперт, а Этапа нет или он не самооценочный
    // 3. Если ты партнёр по Соте, а Этапа нет или он не самооценочный
    // 4, Если ты имеешь одну из разрешенных ролей

    const festival:IFestival = useFestDataStore.getState().festival 
    if(!isStatusOpened()) return false

    const isUserOwner: boolean = isProjectOwner( project )

    const is : boolean = milestone?.is_auto_rait
        ?
        isUserOwner
            ?
            isRoleAccess([ "Tutor", "Project_author" ], milestone)
            :
            false
        : 
        festival.isOwnerRate
            ?
            isAccessByRole( milestone, project )
            :
            isAccessByRole( milestone, project ) && !isUserOwner 
        
    // console.group("useIsExpert")
    // console.log( label )
    // console.log( milestone )
    // console.log( useMainStore.getState().user )
    // console.log( project ) 
    // console.log( is ) 
    // console.groupEnd()

    return is
}

const isAccessByRole = (milestone: IMilestone, project:IProject): boolean => { 
    if(!!(milestone?.roles || [] ).filter( r => { 
        return !!["Expert"].includes(r) && isRole(["Expert"])
    }).length ) {
        return true
    }
    if(!!(milestone?.roles || [] ).filter( r => { 
        return !![ "Tutor", "Project_author", "Project_member" ].includes(r)  && 
            isRole([ "Tutor", "Project_author", "Project_member" ])
    }).length ) {  
        return isMyPartner( project )
    }
    return false
}

const isRoleAccess = (roles: Role[], milestone: IMilestone): boolean => {
    if(milestone?.roles) {
        return !!isRole( milestone?.roles )
    }
    return !isRole( roles );
}
const isProjectOwner = ( project: IProject, user: IUser | null = null): boolean => {
    if(!user) user = useMainStore.getState().user
    if(!user) return false
    return user.id === project.tutor?.id ||
        user.id === project.leader?.id || 
        user.id === project.member0?.id || 
        user.id === project.member1?.id || 
        user.id === project.member2?.id || 
        user.id === project.member3?.id || 
        user.id === project.member4?.id || 
        user.id === project.member5?.id || 
        user.id === project.member6?.id || 
        user.id === project.member7?.id || 
        user.id === project.member8?.id || 
        user.id === project.member9?.id 
}

export { 
    useUserOwner, 
    useUserAction,
    useOwnerPartner,
    useMyProjects,
    useIsExpert,
    isProjectOwner
}