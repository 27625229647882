import { ID } from "@/libs/interfaces/layouts"
import FieldInput from "@/libs/scalars"
import { SCALAR_TYPES, VerticalType } from "@/libs/scalars/interface/IScalarForm"
import { MediaChooser } from "@/libs/useful"
import { __ } from "@/libs/utilities"
import { Card, Slider } from "@blueprintjs/core"
import { cardTypes } from "../../data/cardTypes"
import { displayProjectResult } from "../../data/displayProjectResult"
import { CARD_TYPE, FESTIVAL_RATING_TYPES, IFestival, ILand } from "../../data/interfaces"
import { raitingTypes } from "../../data/raitingTypes"

/* Protopia Ecosystem component */
export interface IFestSettingsFormProps { 
   festival: IFestival 
   setFestival: ( festival:IFestival ) => void
}
const FestSettingsForm = ({festival, setFestival, ...props}: IFestSettingsFormProps): JSX.Element => {
    // const [festival, setFestival] = useState<IFestival>( props.festival || {} as IFestival )

    const onDefaultImage = ( url: string, file: any, ID: ID ) =>
    {
        setFestival({...festival, defaultProjectThumbnail: url, defaultProjectThumbnailName: file.name})
    } 
    const onValueChange = (field: string, value: any) =>
    {
        //console.log(field, value)
        let fest: any = {...festival}
        fest[field] = value
        setFestival( fest )
    }
    
    return <>  
        <div className='row'>
            <div className='col-12 '>                
                <Card interactive={false} className="p-4 mb-2 pb-5" data-element="icon,default_member_thrumb">
                    <div className="layout-label-vert  mb-1">{__("Icons")}</div>
                    <div className="row"> 
                        <div className="col-12" data-element="default_member_thrumb">
                            <div className="small mt-1">{__("Default Project's icon")}</div>
                            <MediaChooser
                                url={festival.defaultProjectThumbnail!}
                                prefix={"deficon"}
                                height={160}
                                padding={0}
                                onChange={ onDefaultImage}
                                id={festival.defaultProjectThumbnail!}
                                isURLHide={true}
                                isDescr={false}
                                hideLib
                                limitSize={2000000}
                            />
                        </div>
                    </div>
                </Card>
                <Card interactive={false} className="p-4 mb-2" data-element="rating_type">
                    <div className="layout-label-vert  mb-1">{__("Rating method")}</div>
                    <FieldInput
                        title={__("")}						
                        vertical={VerticalType.VERTICAL}
                        editable
                        value={festival.ratingType}
                        type={SCALAR_TYPES.RADIO}
                        values={ raitingTypes() } 
                        onChange={ value => onValueChange("ratingType", value) }
                    />
                </Card>
                <Card interactive={false} className="p-4 mb-2" data-element="display_project_result">
                    <div className="layout-label-vert  mb-1">{__("Display Project's result method")}</div>
                    <FieldInput					
                        vertical={VerticalType.VERTICAL}
                        value={ festival.displayProjectResult }
                        type={SCALAR_TYPES.RADIO}
                        editable
                        values={ displayProjectResult() }
                        default="average" 
                        onChange={ value => onValueChange("displayProjectResult", value) }
                    />
                </Card>
                <Card interactive={false} className="p-4 mb-2" data-element="isProjectsVerify">
                    <div className="layout-label-vert  mb-1">
                        {__("Is projects need verify from moderator")}
                    </div>
                    <FieldInput					
                        vertical={VerticalType.VERTICAL}
                        value={ festival.isProjectsVerify }
                        type={SCALAR_TYPES.BOOLEAN}
                        editable 
                        default={true}
                        onChange={ value => onValueChange("isProjectsVerify", value) }
                    />
                </Card>
                <Card interactive={false} className="p-4 mb-2" data-element="isHideNotVerifiedProjects">
                    <div className="layout-label-vert  mb-1">{__("Hide not verified Projects in list?")}</div>
                    <input
                        type="checkbox"
                        className="_checkbox"
                        checked={festival.isHideNotVerifiedProjects}
                        onChange={ evt => onValueChange("isHideNotVerifiedProjects", evt.target.checked) }
                        id="isHideNotVerifiedProjects"
                    />
                    <label htmlFor="isHideNotVerifiedProjects"> </label>
                </Card>
                {
                    festival.ratingType === FESTIVAL_RATING_TYPES.INDEPENDENT_GRADE 
                        || festival.ratingType === FESTIVAL_RATING_TYPES.EACH_CATEGORY
                        ?
                        <Card interactive={true} className="p-4 mb-2" data-element="max_raiting">
                            <div className="layout-label-vert  mb-1">{__("Max raiting setting for independing raiting")}</div>
                            <Slider
                                min={0}
                                max={10}
                                stepSize={1}
                                labelStepSize={1}
                                onChange={value => onValueChange("maxRating", value)}
                                value={festival.maxRating || 0}
                                vertical={false}
                            />
                        </Card>
                        :
                        null
                }
                {
                    festival.ratingType === FESTIVAL_RATING_TYPES.INDEPENDENT_GRADE 
                    || festival.ratingType === FESTIVAL_RATING_TYPES.EACH_CATEGORY
                        ?
                        <Card interactive={false} className="p-4 mb-2" data-element="isIndependentGradeCritery">
                            <div className="layout-label-vert  mb-1">{__("Maximize grade each critery")}</div>
                            <input
                                type="checkbox"
                                className="_checkbox"
                                checked={festival.isIndependentGradeCritery}
                                onChange={evt => onValueChange("isIndependentGradeCritery", evt.target.checked)}
                                id="isIndependentGradeCritery"
                            />
                            <label htmlFor="isIndependentGradeCritery"> </label>
                        </Card>
                        :
                        null
                }
                <Card interactive={false} className="p-4 mb-2" data-element="isOwnerRate">
                    <div className="layout-label-vert  mb-1">{__("Expert can evaluate projects of which he is the owner")}</div>
                    <input
                        type="checkbox"
                        className="_checkbox"
                        checked={festival.isOwnerRate || false }
                        onChange={ evt => onValueChange("isOwnerRate", evt.target.checked) }
                        id="isOwnerRate"
                    />
                    <label htmlFor="isOwnerRate"> </label>
                </Card> 
                <Card interactive={false} className="p-4 mb-2" data-element="is_experts_criteries">
                    <div className="layout-label-vert  mb-1">{__("Experts's criteries enabled")}</div>
                    <input
                        type="checkbox"
                        className="_checkbox"
                        checked={festival.isExpertsCriteries}
                        onChange={ evt => onValueChange("isExpertsCriteries", evt.target.checked) }
                        id="is_experts_criteries"
                    />
                    <label htmlFor="is_experts_criteries"> </label>
                </Card>
                <Card interactive={false} className="p-4 mb-2" data-element="is_ganres">
                    <div className="layout-label-vert  mb-1">{__("Is ganres exists")}</div>
                    <input
                        type="checkbox"
                        className="_checkbox"
                        checked={festival.isGanres}
                        onChange={ evt => onValueChange("isGanres", evt.target.checked) }
                        id="is_ganres"
                    />
                    <label htmlFor="is_ganres"></label>
                </Card>
                <Card interactive={false} className="p-4 mb-2" data-element="is_register">
                    <div className="layout-label-vert  mb-1">{__("Visitors can create accounts")}</div>
                    <input
                        type="checkbox"
                        className="_checkbox"
                        checked={ festival.isRegisterUser}
                        onChange={ evt => onValueChange("isRegisterUser", evt.target.checked) }
                        id="is_register"
                    />
                    <label htmlFor="is_register"></label>
                </Card>
                <Card interactive={false} className="p-4 mb-2" data-element="isCreateAuthorWithProject">
                    <div className="layout-label-vert  mb-1">{__("Visitors can create new Project")}</div>
                    <input
                        type="checkbox"
                        className="_checkbox"
                        checked={ festival.isRegisterProject}
                        onChange={ evt => onValueChange("isRegisterProject", evt.target.checked) }
                        id="is_register_project"
                    />
                    <label htmlFor="is_register_project"></label>

                    <div className="layout-label-vert  mb-1">{__("Create author account with register new Project")}</div>
                    <input
                        type="checkbox"
                        className="_checkbox"
                        checked={ festival.isCreateAuthorWithProject}
                        onChange={ evt => onValueChange("isCreateAuthorWithProject", evt.target.checked) }
                        id="isCreateAuthorWithProject"
                    />
                    <label htmlFor="isCreateAuthorWithProject"></label>
                </Card>
                <Card interactive={false} className="p-4 mb-2" data-element="is_diaries">
                    <div className="layout-label-vert  mb-1">{__("Project authors keep a diary")}</div>
                    <input
                        type="checkbox"
                        className="_checkbox"
                        checked={festival.isDiary}
                        onChange={ evt => onValueChange("isDiary", evt.target.checked) }
                        value="1"
                        id="is_diaries"
                    />
                    <label htmlFor="is_diaries"></label>
                </Card> 
                <Card interactive={false} className="p-4 mb-2" data-element="member_card">                       
                    <FieldInput
                        type={SCALAR_TYPES.IMAGE_RADIO}
                        title={__("Default Member's card")}
                        vertical={VerticalType.VERTICAL}
                        value={ festival.memberCardType } 
                        editable 
                        values={ cardTypes() } 
                        onChange={ value => onValueChange("memberCardType", value._id) }
                    /> 
                    <FieldInput
                        type={SCALAR_TYPES.BOOLEAN}
                        title={__("User may change type of cards display")}
                        vertical={VerticalType.VERTICAL}
                        value={ festival.isChangeCardType } 
                        editable 
                        values={ cardTypes() } 
                        onChange={ value => onValueChange("isChangeCardType", value ) }
                    />
                </Card>
                {
                    festival.memberCardType === CARD_TYPE.CARD 
                        ?
                        <Card interactive={false} className="p-4 mb-2" data-element="member_card_height">
                            <div className="layout-label-vert mb-1">{__("Height of card's thumbnail")}</div>							
                            <Slider
                                min={0}
                                max={400}
                                stepSize={1}
                                labelStepSize={50}
                                onChange={ value => onValueChange("memberCardHeight", value) }
                                onRelease={ value => onValueChange("memberCardHeight", value) }
                                //labelRenderer={ festival.memberCardHeight! }
                                value={ festival.memberCardHeight || 100 }
                                vertical={ false }
                            />  
                        </Card>
                        
                        :
                        null
                } 
                <Card interactive={false} className="p-4 mb-2" data-element="member_card_height">
                    <div className="layout-label-vert mb-1">{__("project Members Count")}</div>							
                    <Slider
                        min={0}
                        max={10}
                        stepSize={1}
                        labelStepSize={1}
                        onChange={ value => onValueChange("projectMembersCount", value) }
                        onRelease={ value => onValueChange("projectMembersCount", value) } 
                        value={ festival.projectMembersCount || 10 }
                        vertical={ false }
                    />  
                </Card>
                <Card interactive={false} className="p-4 mb-2" data-element="fmru_available_roles">
                    <div className="layout-label-vert  mb-2">{__("Available special roles")}</div>
                    <FieldInput
                        type={SCALAR_TYPES.CHECKBOX}
                        vertical={VerticalType.VERTICAL}
                        value={ festival.availableRoles || [] } 
                        editable 
                        values={ festival.roles || [] } 
                        onChange={ value => onValueChange("availableRoles", value) }
                    />
                </Card>
            </div>
        </div>
    </>
}
export default FestSettingsForm