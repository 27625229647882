import { IAppoloField, ID, ITax, ITaxonomyFilter } from "@/libs/interfaces/layouts"
import FieldInput from "@/libs/scalars"
import { SCALAR_TYPES, VerticalType } from "@/libs/scalars/interface/IScalarForm"
import { __ } from "@/libs/utilities"
import { IState, useMainStore } from "@/settings/zustand"
import { useEffect, useState } from "react"
import { IAdminStore, useAdminStore } from "../../data/store"

/* Protopia Ecosystem component */
export interface ITaxonomyFilterElementProps {
    field: IAppoloField
    onFilter: (tax_name: string, term_ids: Number[]) => void
}
const TaxonomyFilterElement = ({field, onFilter}: ITaxonomyFilterElementProps): JSX.Element => {
    const landId:ID = useMainStore( (state:IState) => state.landId )
    const [select, setSelect] = useState<ITax[]>( [] )
    // useEffect(() => useAdminStore.subscribe(
    //     (state: IAdminStore) => setSelect( 
    //         state.taxonomies
    //             .filter(t => t.tax_name === field.component)[0]
    //             .term_ids
    //                 .map(t=> ({id:t, title: ""} as ITax)) 
    //     )
    //   ), [])
    const changeSelect = ( val: ITax[] ) => { 
        setSelect( val )
        onFilter( field.component!, val.map( t => Number(t.id) ) )
    }

    return <div className="p-2 w-32 w-sm-100 card m-1 opacity_75" >
        <div className="title text-secondary">
            { __( field.title ) }
        </div>
        <div className="pl-4">
            <FieldInput
                editable
                vertical={VerticalType.VERTICAL}
                value={Array.isArray( select ) ? select : []}
                onChange={ changeSelect }
                type={SCALAR_TYPES.EXTERNAL_ARRAY} 
                component={field.component}
                landId={landId}
                notClear
            />
        </div>                
    </div>
}
export default TaxonomyFilterElement