import IScalarForm from '../interface/IScalarForm';
import { __, sprintf } from '@/libs/utilities';
import Scalar from './Scalar';
import { useEffect, useState } from 'react';

const Upload = (props: IScalarForm) : JSX.Element => { 
    return <Scalar 
        { ...props } 
        enabledForm={<UploadEnabled {...props} />}
        desabledForm={<UploadDesabled {...props} />}
    />
}
export const UploadEnabled = (props: IScalarForm) : JSX.Element =>  {
    const BYTES: number     = 1048.575
    const maxKb: number     = props.limitSize || 200
    const maxSize: number   = maxKb * BYTES

    const [fileName, setFileName] = useState<string>("")
    const [fileSize, setFileSize] = useState<number>(0)
    useEffect(() => {
        setFileName( "" )
        setFileSize(0)
    }, [props.onClear] )
    const onChange = (evt: any): void =>
    {  
        let file = evt.currentTarget.files[0]
        if(file.size > maxSize)
        {
            alert(
                sprintf(
                     __("File is too big. Maximum file size is %skB. Now: %skB."), 
                     Math.floor(maxKb).toString(),
                     Math.floor(file.size / BYTES).toString() 
                ) 
            )
            return
        }
        let reader = new FileReader()     
        reader.readAsDataURL(file)
        reader.onload = function( theFile ) {
            return function (e: any) { 
                if(  props.onChange ) {
                    setFileName( theFile.name )
                    setFileSize( theFile.size ) 
                    props.onChange( e.target.result, theFile.name, theFile ) 
                }
			}
        }( file )
      
        reader.onerror = function() {
            console.log(reader.error);
        };
    }

    return <div className={`d-flex w-100 align-items-start ${props.className ? props.className : ""}`}> 
        <div 
            className={`flex-centered position-relative flex-column pe-bg  ${props.className ? props.className : ""}`} 
            style={{
                height: props.height || 50,
                width:  props.width  || "100%"
            }} 
            title={fileName}
        >
            <input 
                accept={ props.accept }
                type="file"
                className='opacity_0 w-100 h-100 pointer '
                placeholder={__(" ")}
                value={ "" }
                onChange={ onChange } 
            />
            <div 
                className="  overflow-hidden p-4 position-absolute top left w-100 h-100 flex-centered flex-column bg-secondary untouchble"
               
            >
                <div className={`mb-2 text-light title text-center ${fileName ? "text-overflow" : ""}`}>
                    {
                        fileName
                            ?
                            fileName
                            :
                            __( props.placeholder || "Upload file from computer" )
                    }
                </div>
                <div className='text-light'>
                    { fileName }
                    <small>
                    { 
                        fileSize 
                            ? 
                            fileSize / BYTES > 1000 
                                ?
                                (Math.floor(  fileSize / BYTES)/ 1000).toString() + " Mb"
                                :
                                Math.floor(  fileSize / BYTES).toString() + " Kb" 
                            : 
                            sprintf( 
                                __( "Maximum size is %s" ), 
                                maxKb > 1000 
                                    ? 
                                    `${(maxKb/ 1000).toString()} Mb` 
                                    : 
                                    `${maxKb.toString()} Kb` 
                            ) 
                    }
                    </small>
                </div>
            </div>
            
        </div>
    </div>
}

export const UploadDesabled = (props: IScalarForm) : JSX.Element => {
    return <div className="d-flex align-items-center">
        <div className='lead opacity_75'> 
            <i className="fas fa-download mr-2" />
        </div>
        <a 
            className=' d-flex align-items-center pe-file-input-link' 
            href={props.value} 
            style={{ width: props.width }}
            download={ __( props.field || "download file" ) }
            target="_blank" 
            rel="noreferrer"
        >
            { __( props.field || "download file" ) }
        </a> 
    </div>
} 

export default Upload