import { __ } from '@/libs/utilities'
import { useEffect, useMemo, useState } from 'react'
import { NavLink, useParams } from 'react-router-dom'
import CabinetCarousel from './CabinetCarousel' 
import { useFestival } from '../../hooks/festival'
import { ID } from '@/libs/interfaces/layouts'
import { useFestDataStore } from '../../data/store'
import { useMainStore } from '@/settings/zustand'
import switchToBlog from '@/modules/pe-basic-module/hooks/switchToBlog'
import { getAdminRoutes } from '@/modules/pe-topos-module/data/utils/getAdminRoutes'

const Cabinet = (props:any):JSX.Element => {
    const [blocks, setBlocks]   = useState<string[]>( [] )
    const allRoutes             = useMemo(() => getAdminRoutes(), [])
    const params = useParams()
    const festId: ID = params.landId || "-1"
    const isLoading = useFestival( true, festId )

    useEffect(() => {
        let _blocks: string[] = [] 
        Object.keys( allRoutes ).forEach(b => {
            if(! _blocks.includes( allRoutes[b].group )) {
                _blocks.push( allRoutes[b].group )
            }
        })
        setBlocks( _blocks )
    }, [ allRoutes ])

    useEffect(() => {
        useFestDataStore.setState({ festId })
        useMainStore.setState({landId: festId})
        switchToBlog( festId )
    }, [festId])
    
    return <>
        <CabinetCarousel />
        {
            blocks.map(block => <Section block={block} allRoutes={allRoutes} key={block} />)
        }   
    </>

}

export default Cabinet 

interface ISectionProps {
    block: string
    allRoutes: any
}
const Section = ({ block, allRoutes }:ISectionProps) => {
    const mine = Object.keys(allRoutes).filter( (r: string) =>  allRoutes[r].group === block ) 
    const icon = mine[0]
        ?
        allRoutes[mine[0]].icon
        :
        <></>

    const rightWidget: JSX.Element = mine[0]
        ?
        allRoutes[mine[0]].rightMenuWidget || <></>
        :
        <></>

    return <div className='card mb-4'>
        <div className="card-body p-0">
            <div className="d-flex justify-content-between align-items-center flex-wrap border-bottom-1 border-secondary ">
                <h5 className='card-title pt-3 pl-4 pb-1'>
                    <span className="mr-3">{icon}</span> {__( block )}
                </h5> 
                <div className="pr-4 px-1">
                    { rightWidget }
                </div> 
            </div>
            <div className='w-100'>
                <Block mine={mine} />
            </div>
        </div>
    </div> 
}

interface IBlokProps {
    mine: any[] 
}
const Block = (props: IBlokProps) => {
    const allRoutes = getAdminRoutes()
    const ug: any = {}
    props.mine
        .forEach((r: string) => {
            ug[ allRoutes[ r ].undergroup ] = [
                ...( ug[ allRoutes[ r ].undergroup ] || [] ),
                { ...allRoutes[ r ], route: r }
            ]
        })

    // console.log(ug);
    
    return Object.keys(ug)
        .map( (key, i) => {
            return <div key={key}>
                {
                    key !== "undefined" && <div className="pt-2 pl-4 lead h">
                        { __(key) }
                    </div>
                }
                <div className={`d-flex justify-content-between align-items-center flex-wrap px-4 py-3 ${ i < Object.keys(ug).length - 1 ? "border-bottom-1 border-secondary-light " : "" } `}>
                    <div className="d-flex flex-wrap">
                    {
                        ug[key]
                            .filter((item: any) => item.position === "left" )
                            .map( (item: any) => {
                                // const item: any = allRoutes[ r ] 
                                const className: string = item.position === "left"
                                    ?
                                    "btn-outline-secondary"
                                    :
                                    "btn-link"
                                return <NavLink 
                                    to={ item.route } 
                                    className={`btn btn-sm  text-force-contrast mr-1 mb-1 ${className} `}
                                    key={ item.route } 
                                >
                                    {__( item.title )}
                                </NavLink> 
                            })
                    } 
                    </div> 
                    <div className="d-flex flex-wrap">
                    {
                        ug[key]
                            .filter((item: any) => item.position === "right" )
                            .map( (item: any) => {
                                // const item: any = allRoutes[ r ] 
                                const className: string = item.position === "left"
                                    ?
                                    "btn-outline-secondary"
                                    :
                                    "btn-link"
                                return <NavLink 
                                    to={ item.route } 
                                    className={`btn btn-sm  text-force-contrast mr-1 mb-1 ${className} `}
                                    key={ item.route } 
                                >
                                    {__( item.title )}
                                </NavLink> 
                            })
                    } 
                    </div> 
                </div> 
            </div>
        }) 
}
